import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";

const columns = [
  {
    id: "flux",
    label: "Flux",
    type: "select",
    options: [
      { label: "Ventilation naturelle", value: "Ventilation naturelle" },
      {
        label: "Infiltration des fenêtres",
        value: "Infiltration des fenêtres",
      },
      { label: "Extracteur", value: "Extracteur" },
      { label: "Simple flux", value: "Simple flux" },
      { label: "Double flux", value: "Double flux" },
      {
        label: "Entrée d’air haute et basse",
        value: "Entrée d’air haute et basse",
      },
      { label: "Autre", value: "Autre" },
    ],
    width: 200,
  },
  {
    id: "fluxAutre",
    label: "Flux (autre)",
    type: "text",
    condition: (value) => value.flux == "Autre",
  },
  {
    id: "puissance",
    label: "Puissance",
    type: "number",
    width: 200,
  },
];

const Ventilation = (props) => {
  let initialValue = [];

  if (
    props.initialValue &&
    props.initialValue.appartements &&
    props.initialValue.appartements.length > 0
  ) {
    initialValue = props.initialValue.appartements;
  }

  const [descriptionBatiment, setDescriptionBatiment] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (index, name, value) => {
    const d = Object.assign([], descriptionBatiment);
    d[index].ventilation[name] = value;
    setDescriptionBatiment(d);
  };

  const submit = () => {
    const data = {};
    data.chauffageInfo = props.initialValue.chauffageInfo;
    data.appartements = descriptionBatiment;
    props.onSubmit(data);
  };

  if (descriptionBatiment.length < 1) {
    return (
      <Box
        sx={{
          m: "40px",
        }}
      >
        <Typography variant="h2" textAlign={"center"}>
          {" "}
          Renseignez les informations Ventilation depuis l’onglet "Description
          du bâtiment"
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {descriptionBatiment.map((appartement, index) => {
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        if (column.type === "select") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Select
                                placeholder={column.label}
                                name={column.key}
                                labelId={column.label}
                                id={column.label}
                                value={appartement.ventilation?.[column.id]}
                                label={column.label}
                                onChange={(e) =>
                                  handleChange(index, column.id, e.target.value)
                                }
                                sx={{
                                  width: column.width,
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.options.map((option, index) => {
                                  return (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              width: column.width,
                              minWidth: column.minWidth,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <TextField
                              label={column.label}
                              type="text"
                              disabled={
                                column.condition
                                  ? !column.condition(appartement.ventilation)
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(index, column.id, e.target.value)
                              }
                              value={appartement.ventilation?.[column.id]}
                              name={column.id}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default Ventilation;
