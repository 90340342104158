import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Formulaire from "scenes/formulaire";
import { ToastContainer } from "react-toastify";
import Report from "scenes/report";
import { useIsOnline } from "react-use-is-online";
import { toastSuccess } from "services/project.service";
import { useEffect } from "react";
import { offlineManager } from "Manager/OfflineManager";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  const { isOnline } = useIsOnline();

  const [networkStatus, setNetworkStatus] = useState(isOnline);

  useEffect(() => {
    if (isOnline && !networkStatus && offlineManager.getData()) {
      toastSuccess("You are now online, data will be synced");
      offlineManager.syncData();
    }
    setNetworkStatus(isOnline);
  }, [isOnline]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={isAuth ? <Navigate to="/projects" /> : <LoginPage />}
            />
            <Route
              path="/projects"
              element={isAuth ? <HomePage /> : <Navigate to="/" />}
            />
            <Route
              path="/report/:id"
              element={isAuth ? <Report /> : <Navigate to="/" />}
            />
            <Route
              path="/formulaire/:id"
              element={isAuth ? <Formulaire /> : <Navigate to="/" />}
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
