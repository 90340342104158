import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";

import * as React from "react";

const VentilationModal = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const formulaireSchema = yup.object().shape({
    flux: yup.string().required("Flux is required"),
    fluxAutre: yup.string().optional(),
    puissance: yup.number().optional(),
  });

  const initialValues = {
    flux: "",
    fluxAutre: "",
    puissance: "",
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    props.onSubmit(values);
  };

  return (
    <Box
      width={"100%"}
      p="2rem"
      m="2rem auto"
      backgroundColor={palette.background.default}
    >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={props.initialValue || initialValues}
        validationSchema={formulaireSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* BUTTONS */}
            <Box sx={{ textAlign: "right" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Enregistrer
              </Button>
            </Box>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <FormControl component="fieldset" sx={{ gridColumn: "span 4" }}>
                <FormLabel component="legend">FLux</FormLabel>
                <RadioGroup
                  aria-label="FLux"
                  name="flux"
                  value={values.flux}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Ventilation naturelle"
                    control={<Radio />}
                    label="Ventilation naturelle"
                  />
                  <FormControlLabel
                    value="Infiltration des fenêtres"
                    control={<Radio />}
                    label="Infiltration des fenêtres"
                  />
                  <FormControlLabel
                    value="Extracteur"
                    control={<Radio />}
                    label="Extracteur"
                  />
                  <FormControlLabel
                    value="Simple flux"
                    control={<Radio />}
                    label="Simple flux"
                  />
                  <FormControlLabel
                    value="Double flux"
                    control={<Radio />}
                    label="Double flux"
                  />
                  <FormControlLabel
                    value="Entrée d’air haute et basse"
                    control={<Radio />}
                    label="Entrée d’air haute et basse"
                  />
                  <FormControlLabel
                    value="Autre"
                    control={<Radio />}
                    label="Autre à préciser"
                  />
                </RadioGroup>
                {touched.flux && errors.flux && (
                  <FormHelperText error>{errors.flux}</FormHelperText>
                )}
              </FormControl>
              {values.flux == "Autre" && (
                <TextField
                  label="Types d’énergie"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fluxAutre}
                  name="fluxAutre"
                  error={
                    Boolean(touched.fluxAutre) && Boolean(errors.fluxAutre)
                  }
                  helperText={touched.fluxAutre && errors.fluxAutre}
                  sx={{ gridColumn: "span 4" }}
                />
              )}
              <TextField
                label="Puissance"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.puissance}
                name="puissance"
                error={Boolean(touched.puissance) && Boolean(errors.puissance)}
                helperText={touched.puissance && errors.puissance}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default VentilationModal;
