import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import * as React from "react";
import Formulaire from "components/Formulaire";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const Architecture = (props) => {
  let defaultMitoyennete = [];
  if (props.initialValue?.mitoyennete == "Oui") {
    if (Array.isArray(props.initialValue.mitoyennete)) {
      console.log("is array", props.initialValue.mitoyennete);
      defaultMitoyennete = props.initialValue.mitoyennete;
    } else {
      defaultMitoyennete.push({
        totalPartiel: props.initialValue.totalPartiel,
        surface: props.initialValue.surface,
      });
    }
  } else if (Array.isArray(props.initialValue?.mitoyennete)) {
    defaultMitoyennete = props.initialValue.mitoyennete;
  }

  const { palette } = useTheme();
  const [mitoyennete, setMitoyennete] = useState(defaultMitoyennete);

  const formStruct = [
    {
      key: "typeArchitecture",
      label: "Type d'architecture",
      type: "select",
      options: [
        { label: "en U", value: "en U" },
        { label: "en L", value: "en L" },
        { label: "en T", value: "en T" },
        { label: "Compact/Allongé", value: "Compact/Allongé" },
      ],
    },
    {
      key: "anneeConstruction",
      label: "Année de construction",
      type: "select",
      options: [
        { label: "Avant 1948", value: "Monopropriété" },
        { label: "Entre 1948 et 1974", value: "Entre 1948 et 1974" },
        { label: "Entre 1975 et 1977", value: "Entre 1975 et 1977" },
        { label: "Entre 1978 et 1982", value: "Entre 1978 et 1982" },
        { label: "Entre 1983 et 1988", value: "Entre 1983 et 1988" },
        { label: "Entre 1989 et 2000", value: "Entre 1989 et 2000" },
        { label: "Après 2001", value: "Après 2001" },
      ],
    },
    { key: "surfaceHabitable", label: "Surface habitable", type: "number" },
    { key: "nombreNiveaux", label: "Nombre de niveau", type: "number" },
    {
      key: "orientationFacade",
      label: "Orientation de la façade principale",
      type: "select",
      options: [
        { label: "Nord", value: "Nord" },
        { label: "Sud", value: "Sud" },
        { label: "Est", value: "Est" },
        { label: "Ouest", value: "Ouest" },
        { label: "Nord-Ouest", value: "Nord-Ouest" },
        { label: "Nord-Est", value: "Nord-Est" },
        { label: "Sud-Ouest", value: "Sud-Ouest" },
        { label: "Sud-Est", value: "Sud-Est" },
      ],
    },
    {
      key: "orientation",
      label: "orientation",
      type: "select",
      options: [
        { label: "Nord", value: "Nord" },
        { label: "Sud", value: "Sud" },
        { label: "Est", value: "Est" },
        { label: "Ouest", value: "Ouest" },
        { label: "Nord-Ouest", value: "Nord-Ouest" },
        { label: "Nord-Est", value: "Nord-Est" },
        { label: "Sud-Ouest", value: "Sud-Ouest" },
        { label: "Sud-Est", value: "Sud-Est" },
      ],
      conditional: "mitoyennete",
      conditionalValue: "Oui",
    },
    {
      key: "nombreAppartements",
      label: "Nombre d’appartements",
      type: "number",
    },
    {
      key: "typeCompteurParAppartement",
      label: "Type de compteur(s) par appartement",
      type: "select",
      options: [
        { label: "Monophasé", value: "Monophasé" },
        { label: "Triphasé", value: "Triphasé" },
        { label: "Monophasé et Triphasé", value: "Monophasé et Triphasé" },
      ],
    },
    {
      key: "typePlafond",
      label: "Type de plafond",
      type: "formControl",
      options: [
        { label: "Toiture terrasse", value: "Toiture terrasse" },
        { label: "Comble aménagé", value: "Comble aménagé" },
        { label: "Comble non aménagé", value: "Comble non aménagé" },
      ],
    },
    {
      key: "typePlancher",
      label: "Type de plancher",
      type: "formControl",
      options: [
        { label: "Sur terre-plein", value: "Sur terre-plein" },
        { label: "Sur vide sanitaire", value: "Sur vide sanitaire" },
        { label: "Sur sous-sol", value: "Sur sous-sol" },
      ],
    },
  ];

  const initialValues = props.initialValue || {
    anneeConstruction: "",
    surfaceHabitable: "",
    nombreNiveaux: "",
    orientationFacade: "",
    mitoyennete: mitoyennete,
    totalPartiel: "",
    surface: "",
    orientation: "",
    nombreAppartements: "",
    typeCompteurParAppartement: "",
    typePlafond: "",
    typePlancher: "",
  };

  const mitoyenneteContent = () => {
    return (
      <Box sx={{ mt: "20px" }}>
        <Typography variant="h6"> Mitoyenneté</Typography>
        {mitoyennete.map((m, i) => {
          return (
            <Box
              key={i}
              sx={{
                mt: "10px",
                display: "flex",
                alignItems: "flex-end",
                gap: "20px",
              }}
            >
              <FormControl component="fieldset" sx={{ gridColumn: "span 4" }}>
                <FormLabel component="legend">Total/Partiel</FormLabel>
                <Select
                  name="totalPartiel"
                  labelId={"Total/Partiel"}
                  id={"Total/Partiel"}
                  value={m.totalPartiel}
                  label={"Total/Partiel"}
                  onChange={(e) => {
                    const currentMitoyennete = Object.assign([], mitoyennete);
                    currentMitoyennete[i].totalPartiel = e.target.value;
                    currentMitoyennete[i].surface =
                      e.target.value == "Total"
                        ? null
                        : currentMitoyennete[i].surface;
                    setMitoyennete(currentMitoyennete);
                  }}
                >
                  <MenuItem key="Total" value="Total">
                    Total
                  </MenuItem>
                  <MenuItem key="Partiel" value="Partiel">
                    Partiel
                  </MenuItem>
                </Select>
              </FormControl>
              {m.totalPartiel == "Partiel" && (
                <TextField
                  label="Surface"
                  type="text"
                  onChange={(e) => {
                    const currentMitoyennete = Object.assign([], mitoyennete);
                    currentMitoyennete[i].surface = e.target.value;
                    setMitoyennete(currentMitoyennete);
                  }}
                  value={m.surface}
                  name="surface"
                />
              )}
              <IconButton
                onClick={(e) => {
                  setMitoyennete((prevSystems) =>
                    prevSystems.filter((_, index) => index !== i)
                  );
                }}
                sx={{ fontSize: "25px" }}
              >
                <DeleteIcon
                  sx={{
                    color: palette.primary.red,
                    fontSize: "25px",
                  }}
                />
              </IconButton>
            </Box>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setMitoyennete([
              ...mitoyennete,
              {
                totalPartiel: null,
                surface: null,
              },
            ]);
          }}
          sx={{
            mt: "1rem",
            p: "1rem",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Ajouter une Mitoyenneté{" "}
        </Button>
      </Box>
    );
  };

  return (
    <Box
      width={"100%"}
      p="2rem"
      m="2rem auto"
      backgroundColor={palette.background.default}
    >
      <Formulaire
        formStruct={formStruct}
        initialValues={initialValues}
        onSubmit={(values) => {
          values.mitoyennete = mitoyennete;
          props.onSubmit(values);
        }}
        children={mitoyenneteContent()}
      />
    </Box>
  );
};

export default Architecture;
