import { toast } from "react-toastify";

export function toastError(message) {
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      color: "#941b0c",
      fontFamily: "Cera Pro Medium, Sans-serif",
      fontWeight: 700,
    },
  });
}

export function toastSuccess(message) {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      color: "#467367",
      fontFamily: "Cera Pro Medium, Sans-serif",
      fontWeight: 700,
    },
  });
}

export function toastWarning(message) {
  toast.warning(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      color: "#467367",
      fontFamily: "Cera Pro Medium, Sans-serif",
      fontWeight: 700,
    },
  });
}

async function createProject(userId, name) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/project`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        name,
      }),
    });
    if (!response.ok) {
      throw new Error("An error occurred while creating project");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while creating project", err);
  }
}

async function deleteProject(projectId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while deleting project");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while deleting project", err);
  }
}

async function updateProject(projectId, values) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while creating project");
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while creating project", err);
  }
}

async function syncData(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/sync`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while sync data");
    }

    return await response.json();
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while sync data", err);
  }
}

async function updatePhotoCommProject(projectId, values) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/updatePhotoComm`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while update commentaire photo");
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while update commentaire photo", err);
  }
}

async function updatePhotosProject(projectId, formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/updatePhotos`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      toastError(response.json());
      throw new Error("An error occurred while upload photo");
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while upload photo", err);
  }
}

async function deletePhotosProject(projectId, formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/deletePhotos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while delete photo");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while delete photo", err);
  }
}

async function updateCsvProject(projectId, formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/updateCsv`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while update csv");
    }
    const data = await response.json();
    toastSuccess("CSV updated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function updateCsvProject2(projectId, formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/updateCsv2`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("An error occurred while update csv");
    }
    const data = await response.json();
    toastSuccess("CSV 2 updated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function updateDataXlsx(formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/report/updateData`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      let errMsg = "An error occurred while update data xlsx";
      try {
        const data = await response.json();
        if (data.message) {
          errMsg = data.message;
        }
      } catch (err) {
        console.log(err);
      }
      throw new Error(errMsg);
    }

    const data = await response.json();
    toastSuccess("Xslx updated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function updateBaliseDocx(formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/report/updateDocx`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      let errMsg = "An error occurred while update docx";
      try {
        const data = await response.json();
        if (data.message) {
          errMsg = data.message;
        }
      } catch (err) {
        console.log(err);
      }
      throw new Error(errMsg);
    }

    const data = await response.json();
    toastSuccess("Docx updated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function updateBaliseDocx2(formData) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/report/updateDocx2`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      let errMsg = "An error occurred while update docx";
      try {
        const data = await response.json();
        if (data.message) {
          errMsg = data.message;
        }
      } catch (err) {
        console.log(err);
      }
      throw new Error(errMsg);
    }

    const data = await response.json();
    toastSuccess("Docx 2 updated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function downloadReport(projectId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/generateReport`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message);
    }
    const data = await response.json();
    toastSuccess("Report generated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function downloadReport2(projectId) {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}/generateReport2`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.message);
    }
    const data = await response.json();
    toastSuccess("Report generated successfully");
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error(err.message);
  }
}

async function getProjects(search) {
  const token = localStorage.getItem("token");
  let url = `${process.env.REACT_APP_API_URL}/project`;
  if (search && search != "") {
    url = `${url}?search=${search}`;
  }
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("An error occurred while fetching projects");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while fetching projects", err);
  }
}

async function getProject(projectId) {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/project/${projectId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("An error occurred while fetching project");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("err", err.message);
    toastError(err.message);
    throw new Error("An error occurred while fetching project", err);
  }
}

export const projectService = {
  createProject,
  deleteProject,
  updateProject,
  syncData,
  updatePhotosProject,
  updatePhotoCommProject,
  deletePhotosProject,
  updateCsvProject,
  updateCsvProject2,
  updateDataXlsx,
  updateBaliseDocx,
  updateBaliseDocx2,
  downloadReport,
  downloadReport2,
  getProjects,
  getProject,
};
