import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "scenes/navbar";
import { useTheme } from "@mui/material";
import InfoClient from "./infoClient";
import { useState } from "react";
import { useEffect } from "react";
import Architecture from "./architecture";
import Chauffages from "./chauffages";
import { useParams } from "react-router-dom";
import { projectService, toastWarning } from "services/project.service";
import Parois from "./parois";
import PlanchersBas from "./planchersBas";
import PlafondsCombles from "./plafondsCombles";
import PlafondsRampants from "./plafondsRampants";
import SousSol from "./sousSol";
import Niveaux from "./niveaux";
import Combles from "./combles";
import Ecs from "./ecs";
import Photos from "./photos";
import Ventilation from "./ventilation";
import DescriptionBatiment from "./descriptionBatiment";
import { toast } from "react-toastify";
import Ouvrants from "./ouvrants";
import PlafondsRDC from "./plafondsRDC";
import Enr from "./enr";
import { useIsOnline } from "react-use-is-online";
import { offlineManager } from "Manager/OfflineManager";

function a11yProps(index) {
  return {
    id: `green-formulaire-tab-${index}`,
    "aria-controls": `green-tabpanel-${index}`,
  };
}

const Formulaire = () => {
  const { palette } = useTheme();
  let { id } = useParams();

  const { isOffline } = useIsOnline();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    const getProject = async () => {
      try {
        const response = await projectService.getProject(id);
        setProject(response);
        setError(null);
      } catch (error) {
        console.log("an error occurred:", error);
        setError(error);
      }
    };

    if (isOffline) {
      const offlineProjectData = offlineManager.getData(id);
      let currentProject = Object.assign({}, project);
      if (!project) {
        currentProject["data"] = offlineProjectData;
      }
      setProject(currentProject);
      setError(null);
    } else {
      getProject();
    }
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmitForm = (onglet, data) => {
    const updateProject = async () => {
      try {
        const response = await projectService.updateProject(id, {
          key: onglet,
          data: data,
        });
        setProject(response);
        toast.success(`${"Modification successfully saved"}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            color: "#467367",
            fontFamily: "Cera Pro Medium, Sans-serif",
            fontWeight: 700,
          },
        });
      } catch (error) {
        console.log("an error occurred:", error);
      }
    };

    console.log("isOffline : ", isOffline);
    if (isOffline) {
      offlineManager.saveData(id, onglet, data);
      let currentProject = Object.assign({}, project);
      currentProject["data"][onglet] = data;
      setProject(currentProject);
      toastWarning("You are offline, data is temporary saved in local storage");
    } else {
      updateProject();
    }
  };

  const getOnglets = () => {
    return [
      {
        label: "Informations du client",
        component: (
          <InfoClient
            initialValue={project.data?.infoClient}
            onSubmit={(data) => onSubmitForm("infoClient", data)}
          />
        ),
      },
      {
        label: "Architecture de la maison",
        component: (
          <Architecture
            initialValue={project.data?.architecture}
            onSubmit={(data) => onSubmitForm("architecture", data)}
          />
        ),
      },
      {
        label: "Photos",
        component: (
          <Photos initialValue={project.data?.photos} projectId={id} />
        ),
      },
      {
        label: "Parois",
        component: (
          <Parois
            initialValue={project.data?.parois}
            onSubmit={(data) => onSubmitForm("parois", data)}
          />
        ),
      },
      {
        label: "Planchers bas",
        component: (
          <PlanchersBas
            initialValue={project.data?.planchersBas}
            onSubmit={(data) => onSubmitForm("planchersBas", data)}
          />
        ),
      },
      {
        label: "Plafonds combles",
        component: (
          <PlafondsCombles
            initialValue={project.data?.plafondsCombles}
            onSubmit={(data) => onSubmitForm("plafondsCombles", data)}
          />
        ),
      },
      {
        label: "Plafonds rampants",
        component: (
          <PlafondsRampants
            initialValue={project.data?.plafondsRampants}
            onSubmit={(data) => onSubmitForm("plafondsRampants", data)}
          />
        ),
      },
      {
        label: "Plafonds rdc",
        component: (
          <PlafondsRDC
            initialValue={project.data?.plafondsRdc}
            onSubmit={(data) => onSubmitForm("plafondsRdc", data)}
          />
        ),
      },
      {
        label: "Sous-sol/Cave/vide sanitaire",
        component: (
          <SousSol
            initialValue={project.data?.sousSol}
            onSubmit={(data) => onSubmitForm("sousSol", data)}
          />
        ),
      },
      {
        label: "Niveaux",
        component: (
          <Niveaux
            initialValue={project.data?.niveaux}
            onSubmit={(data) => onSubmitForm("niveaux", data)}
          />
        ),
      },
      {
        label: "Ouvrants",
        component: (
          <Ouvrants
            initialValue={project.data?.ouvrants}
            onSubmit={(data) => onSubmitForm("ouvrants", data)}
          />
        ),
      },
      {
        label: "Comble aménagées",
        component: (
          <Combles
            initialValue={project.data?.combles}
            onSubmit={(data) => onSubmitForm("combles", data)}
          />
        ),
      },
      // {
      //   label: "COMBLE perdus et rampants",
      //   component: (
      //     <ComblesPerdus
      //       initialValue={project.data?.comblesPerdus}
      //       onSubmit={(data) => onSubmitForm("comblesPerdus", data)}
      //     />
      //   ),
      // },
      {
        label: "Description du batiment",
        component: (
          <DescriptionBatiment
            initialValue={project.data?.description}
            onSubmit={(data) => onSubmitForm("description", data)}
          />
        ),
      },
      {
        label: "Chauffages",
        component: (
          <Chauffages
            initialValue={project.data?.description}
            onSubmit={(data) => onSubmitForm("description", data)}
          />
        ),
      },
      {
        label: "ECS",
        component: (
          <Ecs
            initialValue={project.data?.description}
            onSubmit={(data) => onSubmitForm("description", data)}
          />
        ),
      },
      {
        label: "Ventilation Par appartement",
        component: (
          <Ventilation
            initialValue={project.data?.description}
            onSubmit={(data) => onSubmitForm("description", data)}
          />
        ),
      },
      // {
      //   label: "ENR",
      //   component: (
      //     <Enr
      //       initialValue={project.data?.enr}
      //       onSubmit={(data) => onSubmitForm("enr", data)}
      //     />
      //   ),
      // },
    ];
  };

  if (error) {
    return (
      <>
        <Navbar />

        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 120px)",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="clamp(1rem, 2rem, 2.25rem)"
            color="warning"
            textAlign="center"
            sx={{ mt: "20px" }}
          >
            {error.message}
          </Typography>
        </Box>
      </>
    );
  }

  if (!project) {
    return (
      <>
        <Navbar />
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 120px)",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="clamp(1rem, 2rem, 2.25rem)"
            color="primary"
            textAlign="center"
            sx={{ mt: "20px" }}
          >
            Loading...
          </Typography>
        </Box>
      </>
    );
  }

  const onglets = getOnglets();

  return (
    <>
      <Navbar />
      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="clamp(1rem, 2rem, 2.25rem)"
        color="primary"
        textAlign="center"
        sx={{ mt: "20px" }}
      >
        {project.name}
      </Typography>
      <Box
        width={"100%"}
        mt="20px"
        backgroundColor={palette.background.default}
        sx={{ height: screenSize.height - 190 }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Green formulaire tabs"
          >
            {onglets.map((value, index) => {
              return (
                <Tab
                  key={index}
                  sx={{
                    fontFamily: "Cera Pro Medium, Sans-serif",
                    fontWeight: 700,
                  }}
                  label={value.label}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box sx={{ height: "90%", overflowY: "scroll" }}>
          {onglets[value].component}
        </Box>
      </Box>
    </>
  );
};

export default Formulaire;
