import * as React from "react";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { projectService } from "services/project.service";
import Navbar from "scenes/navbar";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const columns = [
  {
    id: "name",
    label: "Nom du projet",
    minWidth: 170,
  },

  {
    id: "createdAt",
    label: "Date de création",
    minWidth: 100,
    align: "right",
  },
  { id: "cta", label: "Actions", minWidth: 170, align: "right" },
];

const HomePage = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [projects, setProjects] = useState([]);
  const [projectSearch, setProjectSearch] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [openNewProjectModal, setOpenNewProjectModal] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [displayBackdrop, setDisplayBackdrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDocx, setAnchorElDocx] = useState(null);
  const [anchorElDocx2, setAnchorElDocx2] = useState(null);
  const open = Boolean(anchorEl);
  const openDocx = Boolean(anchorElDocx);
  const openDocx2 = Boolean(anchorElDocx2);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    getProjects();
  }, [projectSearch]);

  const getProjects = async () => {
    try {
      const response = await projectService.getProjects(projectSearch);
      setProjects(response.projects);
      setIsAdmin(response.isAdmin);
    } catch (error) {
      console.log("an error occurred:", error);
    }
  };

  const handleChangeProjectName = (event) => {
    setNewProjectName(event.target.value);
  };

  const createNewProject = () => {
    const createProject = async () => {
      try {
        const response = await projectService.createProject(
          user._id,
          newProjectName
        );
        setOpenNewProjectModal(false);

        const projectId = response._id;
        setDisplayBackdrop(false);
        navigate(`/formulaire/${projectId}`);
      } catch (error) {
        setDisplayBackdrop(false);
        console.log("an error occurred:", error);
      }
    };
    setDisplayBackdrop(true);
    createProject();
  };

  const deleteProject = (projectId) => {
    const deleteProject = async () => {
      try {
        await projectService.deleteProject(projectId);
        getProjects();
      } catch (error) {
        console.log("an error occurred:", error);
      }
    };
    deleteProject();
  };

  // DATA XLSX

  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadDataXlsx = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        submit(file);
      }
    }
  };

  const downloadDataXlsx = () => {
    const token = localStorage.getItem("token");

    fetch(`${process.env.REACT_APP_API_URL}/report/downloadData`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = "DATA.xlsx";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const submit = async (file) => {
    const updateData = async () => {
      try {
        const formData = new FormData();
        formData.append("data_xlsx", file);
        formData.append("filePath", file.name);
        await projectService.updateDataXlsx(formData);
      } catch (error) {
        console.log("upload data xlsx error:", error);
      }
    };
    updateData();
  };

  // DOCX

  const handleCloseDocx = () => {
    setAnchorElDocx(null);
  };

  const uploadBaliseDocx = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        submitDocx(file);
      }
    }
  };

  const downloadBaliseDocx = () => {
    const token = localStorage.getItem("token");

    fetch(`${process.env.REACT_APP_API_URL}/report/downloadDocx`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = "balises.docx";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const submitDocx = async (file) => {
    const updateData = async () => {
      try {
        const formData = new FormData();
        formData.append("balise_docx", file);
        formData.append("filePath", file.name);
        await projectService.updateBaliseDocx(formData);
      } catch (error) {
        console.log("upload docx error:", error);
      }
    };
    updateData();
  };

  const handleCloseDocx2 = () => {
    setAnchorElDocx2(null);
  };

  const uploadBaliseDocx2 = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        submitDocx2(file);
      }
    }
  };

  const downloadBaliseDocx2 = () => {
    const token = localStorage.getItem("token");

    fetch(`${process.env.REACT_APP_API_URL}/report/downloadDocx2`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = "balises2.docx";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const submitDocx2 = async (file) => {
    const updateData = async () => {
      try {
        const formData = new FormData();
        formData.append("balise_docx_2", file);
        formData.append("filePath", file.name);
        await projectService.updateBaliseDocx2(formData);
      } catch (error) {
        console.log("upload docx 2 error:", error);
      }
    };
    updateData();
  };

  return (
    <Box>
      <Navbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={displayBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={openNewProjectModal}
        onClose={() => setOpenNewProjectModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
          }}
        >
          <FlexBetween sx={{ flexDirection: "column", gap: "2rem" }}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Nouveau Projet
            </Typography>
            <TextField
              fullWidth
              label="Nom du Projet"
              onChange={handleChangeProjectName}
              name="nom"
              sx={{ gridColumn: "span 4" }}
            />
            <Button
              type="submit"
              disabled={!newProjectName}
              onClick={() => createNewProject()}
              variant="contained"
              color="primary"
            >
              Créer
            </Button>
          </FlexBetween>
        </Box>
      </Modal>
      <Box>
        <FlexBetween
          gap="1.75rem"
          padding="1.5rem 1.5rem 5px"
          width="fit-content"
        >
          <Button
            type="submit"
            onClick={() => setOpenNewProjectModal(true)}
            variant="contained"
            color="primary"
          >
            Nouveau project
          </Button>
          {isAdmin && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ fontSize: "10px", m: "20px" }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                Data.xslx
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() =>
                    document.getElementById("data_xslsx_button").click()
                  }
                >
                  Upload
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    downloadDataXlsx();
                  }}
                >
                  Download
                </MenuItem>
              </Menu>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ fontSize: "10px", m: "20px" }}
                onClick={(e) => {
                  setAnchorElDocx(e.currentTarget);
                }}
              >
                balises.docx
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElDocx}
                open={openDocx}
                onClose={handleCloseDocx}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() =>
                    document.getElementById("data_docx_button").click()
                  }
                >
                  Upload
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseDocx();
                    downloadBaliseDocx();
                  }}
                >
                  Download
                </MenuItem>
              </Menu>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ fontSize: "10px", m: "20px" }}
                onClick={(e) => {
                  setAnchorElDocx2(e.currentTarget);
                }}
              >
                balises2.docx
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElDocx2}
                open={openDocx2}
                onClose={handleCloseDocx2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() =>
                    document.getElementById("data_docx_button_2").click()
                  }
                >
                  Upload
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseDocx2();
                    downloadBaliseDocx2();
                  }}
                >
                  Download
                </MenuItem>
              </Menu>
            </>
          )}

          <input
            style={{ display: "none" }}
            name="data_xlsx"
            accept={".xlsx"}
            id={"data_xslsx_button"}
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={(e) => {
              uploadDataXlsx(e.target);
            }}
          />
          <input
            style={{ display: "none" }}
            name="balise_docx"
            accept={".docx"}
            id={"data_docx_button"}
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={(e) => {
              uploadBaliseDocx(e.target);
            }}
          />
          <input
            style={{ display: "none" }}
            name="balise_docx_2"
            accept={".docx"}
            id={"data_docx_button_2"}
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={(e) => {
              uploadBaliseDocx2(e.target);
            }}
          />
        </FlexBetween>
        <TextField
          sx={{ ml: "24px", width: 200 }}
          id="filled-basic"
          label="Search project"
          variant="filled"
          onChange={(e) => setProjectSearch(e.target.value)}
        />
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <TableContainer sx={{ maxHeight: screenSize.height - 280 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.length === 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                      colSpan={3}
                    >
                      La liste de project est vide
                    </TableCell>
                  </TableRow>
                )}
                {projects.map((row) => {
                  return (
                    <TableRow
                      onClick={() => {
                        navigate(`/formulaire/${row._id}`);
                      }}
                      key={row._id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{ cursor: "pointer" }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        if (column.id == "cta") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <FlexBetween>
                                <div></div>

                                <Button
                                  type="submit"
                                  sx={{ fontSize: "10px" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/report/${row._id}`);
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  Rapport
                                </Button>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteProject(row._id);
                                  }}
                                  sx={{ fontSize: "25px" }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: palette.primary.red,
                                      fontSize: "25px",
                                    }}
                                  />
                                </IconButton>
                              </FlexBetween>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <Typography variant="h6" component="h2">
                              {column.id == "createdAt"
                                ? format(value, "dd/MM/yyyy HH:mm")
                                : value}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default HomePage;
