import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import Form from "./Form";
import logo from "./../../assets/logo-Green-Energie-analysis.png";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.default}
        p="1rem 6%"
        textAlign="center"
      >
        <img style={{ height: "60px" }} src={logo} alt="green-energy" />
      </Box>
      <Divider
        sx={{ borderColor: theme.palette.primary.main }}
        orientation="horizontal"
      />

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        backgroundColor={theme.palette.background.default}
      >
        <Typography fontWeight="500" variant="h5" sx={{ mb: "1.5rem" }}>
          Bienvenue sur la plateforme Green Energie Analysis
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
