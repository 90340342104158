import { toast } from "react-toastify";

function toastError(message) {
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
// Registers a user
export const userRegisterApi = async (values) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/register`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    toastError(error.message);
    throw new Error(error.message);
  }
};

// Logs a user in
export const userLoginApi = async (values) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.msg);
    }

    return { status: "success", data: data };
  } catch (error) {
    toastError(error.message);
    throw new Error(error.message);
  }
};
