import { Box, useTheme } from "@mui/material";

import * as React from "react";
import Formulaire from "components/Formulaire";

const InfoClient = (props) => {
  const { palette } = useTheme();

  const currentDate = () => {
    const dateNow = new Date(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
      monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();

    return `${date}/${month}/${year}`; // combining to format for defaultValue or value attribute of material <TextField>
  };

  const formStruct = [
    { key: "nom", label: "Nom", type: "text" },
    { key: "prenom", label: "Prénom", type: "text" },
    { key: "adresse", label: "Adresse", type: "text" },
    { key: "codePostal", label: "Code Postal", type: "text" },
    { key: "ville", label: "Ville", type: "text" },
    { key: "telephone", label: "Téléphone", type: "text" },
    { key: "mail", label: "Mail", type: "mail", isOptional: true },
    {
      key: "etatOccupation",
      label: "Etat d’occupation",
      type: "select",
      options: [
        { label: "Monopropriété", value: "Monopropriété" },
        { label: "Copropriété", value: "Copropriété" },
        { label: "SCI", value: "SCI" },
      ],
    },
    { key: "nombreOccupants", label: "Nombre d’occupants", type: "number" },
    {
      key: "revenuFiscal",
      label: "Revenu fiscal",
      type: "number",
      isOptional: true,
    },
    {
      key: "numeroFiscal",
      label: "Numero fiscal",
      type: "text",
      isOptional: true,
    },
    {
      key: "referenceFiscal",
      label: "Reference fiscal",
      type: "text",
      isOptional: true,
    },
    { key: "valorisation", label: "Valorisation", type: "number" },
    { key: "dateVisite", label: "Date de visite", type: "date" },
    { key: "realisePar", label: "Realisé par", type: "text" },
  ];

  const initialValues = {
    nom: "",
    prenom: "",
    adresse: "",
    codePostal: "",
    ville: "",
    telephone: "",
    mail: "",
    etatOccupation: "",
    nombreOccupants: "",
    revenuFiscal: "",
    numeroFiscal: "",
    referenceFiscal: "",
    valorisation: "",
    dateVisite: currentDate(),
    realisePar: "",
  };

  return (
    <Box p="2rem" m="2rem auto" backgroundColor={palette.background.default}>
      <Formulaire
        formStruct={formStruct}
        initialValues={props.initialValue || initialValues}
        onSubmit={props.onSubmit}
      />
    </Box>
  );
};

export default InfoClient;
