import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import Chauffage from "scenes/modalContent/chauffage";
import EcsModal from "scenes/modalContent/ecsModal";
import VentilationModal from "scenes/modalContent/ventilationModal";
import { toastSuccess, toastWarning } from "services/project.service";

const columns = [
  { id: "app", label: "App", width: 30 },
  {
    id: "surface",
    label: "Surface",
    minWidth: 100,
  },
  {
    id: "nbLogementIdentique",
    label: "Nbre de logement identique",
    minWidth: 100,
  },
  {
    id: "nbPiecesPrincipales",
    label: "Nombre de Pieces principales",
    minWidth: 100,
  },
  {
    id: "typeCompteur",
    label: "Type du compteur",
    minWidth: 100,
    type: "select",
    options: [
      { label: "Monophasé", value: "Monophasé" },
      { label: "Triphasé", value: "Triphasé" },
      { label: "Monophasé et Triphasé", value: "Monophasé et Triphasé" },
    ],
  },
  {
    id: "chauffage",
    label: "Chauffage",
    minWidth: 100,
    type: "modal",
    condition: (values) =>
      values == "Collectif ou individuel" || values == "Individuel",
  },
  {
    id: "ecs",
    label: "ECS",
    minWidth: 100,
    type: "modal",
  },
  {
    id: "ventilation",
    label: "Ventilation",
    minWidth: 100,
    type: "modal",
  },
  { id: "cta", label: "Actions", minWidth: 50, align: "right" },
];

const DescriptionBatiment = (props) => {
  const defaultValue = {
    surface: "",
    nbLogementIdentique: "",
    nbPiecesPrincipales: "",
    typeCompteur: "",
    chauffage: "",
    ecs: "",
    capaciteBallon: "",
  };

  let initialValue = [defaultValue];
  let initialChauffageInfo = {
    isOpen: false,
    typeChauffage: null,
    currentChauffage: null,
    data: {},
  };

  let initialEcsInfo = {
    isOpen: false,
    currentEcs: null,
    data: {},
  };

  let initialVentilationInfo = {
    isOpen: false,
    currentVentilation: null,
    data: {},
  };

  if (
    props.initialValue &&
    props.initialValue.appartements &&
    props.initialValue.appartements.length > 0
  ) {
    initialValue = props.initialValue.appartements;
  }

  if (props.initialValue && props.initialValue.chauffageInfo) {
    initialChauffageInfo = props.initialValue.chauffageInfo;
  }

  const { palette } = useTheme();
  const [descriptionBatiment, setDescriptionBatiment] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [chauffageInfo, setChauffageInfo] = useState(initialChauffageInfo);
  const [ecsInfo, setEcsInfo] = useState(initialEcsInfo);
  const [ventilationInfo, setVentilationInfo] = useState(
    initialVentilationInfo
  );

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const submit = async () => {
    const data = {};

    data.chauffageInfo = chauffageInfo;
    data.appartements = descriptionBatiment;
    props.onSubmit(data);
  };

  const handleChangeTypeChauffage = (type) => {
    const chauffage = Object.assign({}, chauffageInfo);
    chauffage.typeChauffage = type;
    if (type == "Collectif" && chauffageInfo.typeChauffage !== "Collectif") {
      chauffage.data = {};
      const d = Object.assign([], descriptionBatiment);
      d.map((app) => {
        app.chauffage = null;
      });
      setDescriptionBatiment(d);
    }

    setChauffageInfo(chauffage);
  };

  const openModal = (column, appartement) => {
    if (column == "chauffage") {
      openChauffageModal(appartement);
    } else if (column == "ecs") {
      openEcsModal(appartement);
    } else if (column == "ventilation") {
      openVentilationModal(appartement);
    }
  };

  const openChauffageModal = (appartement) => {
    const chauffage = Object.assign({}, chauffageInfo);
    chauffage.currentChauffage = appartement;
    chauffage.isOpen = true;
    setChauffageInfo(chauffage);
  };

  const openEcsModal = (appartement) => {
    const ecs = Object.assign({}, ecsInfo);
    ecs.currentEcs = appartement;
    ecs.isOpen = true;
    setEcsInfo(ecs);
  };

  const openVentilationModal = (appartement) => {
    const v = Object.assign({}, ventilationInfo);
    v.currentVentilation = appartement;
    v.isOpen = true;
    setVentilationInfo(v);
  };

  const closeModal = () => {
    closeChauffageModal();
    closeEcsModal();
    closeVentilationModal();
    toastWarning(
      "You're modification will be updated when you click on the Save button"
    );
  };

  const closeChauffageModal = () => {
    const chauffage = Object.assign({}, chauffageInfo);
    chauffage.currentChauffage = null;
    chauffage.isOpen = false;
    setChauffageInfo(chauffage);
  };

  const closeEcsModal = () => {
    const ecs = Object.assign({}, ecsInfo);
    ecs.currentEcs = null;
    ecs.isOpen = false;
    setEcsInfo(ecs);
  };

  const closeVentilationModal = () => {
    const v = Object.assign({}, ventilationInfo);
    v.currentVentilation = null;
    v.isOpen = false;
    setVentilationInfo(v);
  };

  const handleChange = (index, name, value) => {
    let newValue = Object.assign([], descriptionBatiment);
    newValue[index][name] = value;
    setDescriptionBatiment(newValue);
  };

  const addAppartement = () => {
    let newValue = Object.assign([], descriptionBatiment);
    newValue.push(defaultValue);
    setDescriptionBatiment(newValue);
  };

  const deleteAppartement = (index) => {
    setDescriptionBatiment((prevSystems) =>
      prevSystems.filter((_, i) => i !== index)
    );
  };

  const saveChauffageInfo = (data) => {
    if (chauffageInfo.typeChauffage == "Collectif") {
      const ch = Object.assign({}, chauffageInfo);
      ch.data = data;
      setChauffageInfo(ch);
      toastSuccess("chauffage collectif successfully updated");
    } else {
      const d = Object.assign([], descriptionBatiment);
      d[chauffageInfo.currentChauffage].chauffage = data;
      setDescriptionBatiment(d);
      toastSuccess(
        `chauffage appartement ${
          chauffageInfo.currentChauffage + 1
        } successfully updated`
      );
    }
  };

  const saveEcsInfo = (data) => {
    const d = Object.assign([], descriptionBatiment);
    d[ecsInfo.currentEcs].ecs = data;
    setDescriptionBatiment(d);
    toastSuccess(
      `ECS appartement ${
        chauffageInfo.currentChauffage + 1
      } successfully updated`
    );
  };

  const saveventilationInfo = (data) => {
    const d = Object.assign([], descriptionBatiment);
    d[ventilationInfo.currentVentilation].ventilation = data;
    setDescriptionBatiment(d);
    toastSuccess(
      `Ventilation appartement ${
        chauffageInfo.currentChauffage + 1
      } successfully updated`
    );
  };

  const getModalContent = () => {
    if (chauffageInfo.isOpen) {
      return chauffageModalContent();
    } else if (ecsInfo.isOpen) {
      return ecsModalContent();
    } else if (ventilationInfo.isOpen) {
      return ventilationModalContent();
    }
  };

  const chauffageModalContent = () => {
    let initialValue = chauffageInfo.data;
    if (
      chauffageInfo.currentChauffage != null &&
      chauffageInfo.currentChauffage != "Collectif"
    ) {
      initialValue =
        descriptionBatiment[chauffageInfo.currentChauffage].chauffage;
    }

    return (
      <Box>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Chauffage{" "}
          {chauffageInfo.currentChauffage !== "Collectif"
            ? "Appartement " + (chauffageInfo.currentChauffage + 1)
            : chauffageInfo.currentChauffage}
        </Typography>
        <Chauffage
          initialValue={initialValue}
          onSubmit={(data) => saveChauffageInfo(data)}
        />
      </Box>
    );
  };

  const ecsModalContent = () => {
    let initialValue = {};
    if (ecsInfo.currentEcs != null) {
      initialValue = descriptionBatiment[ecsInfo.currentEcs].ecs;
    }

    return (
      <Box>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          ECS {ecsInfo.currentEcs + 1}
        </Typography>
        <EcsModal
          initialValue={initialValue}
          onSubmit={(data) => saveEcsInfo(data)}
        />
      </Box>
    );
  };

  const ventilationModalContent = () => {
    let initialValue = {};
    if (ventilationInfo.currentVentilation != null) {
      initialValue =
        descriptionBatiment[ventilationInfo.currentVentilation].ventilation;
    }

    return (
      <Box>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Ventilation {ecsInfo.currentEcs + 1}
        </Typography>
        <VentilationModal
          initialValue={initialValue}
          onSubmit={(data) => saveventilationInfo(data)}
        />
      </Box>
    );
  };

  return (
    <Box>
      <Modal
        open={chauffageInfo.isOpen || ecsInfo.isOpen || ventilationInfo.isOpen}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
          }}
        >
          {getModalContent()}
        </Box>
      </Modal>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              component="fieldset"
              sx={{ ml: "10px", mb: "20px", gridColumn: "span 4" }}
            >
              <FormLabel component="legend">Type de chauffage</FormLabel>
              <Select
                placeholder="distribution"
                name="distribution"
                labelId="distribution"
                id="distribution"
                label="Distribution"
                value={chauffageInfo.typeChauffage}
                onChange={(e) => handleChangeTypeChauffage(e.target.value)}
                sx={{
                  width: "300px",
                }}
              >
                <MenuItem value="Collectif">Collectif</MenuItem>
                <MenuItem value="Collectif ou individuel">
                  Collectif ou individuel
                </MenuItem>
                <MenuItem value="Individuel">Individuel</MenuItem>
              </Select>
            </FormControl>
            {chauffageInfo.typeChauffage == "Collectif" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  openChauffageModal("Collectif");
                }}
                sx={{
                  m: "12px",
                  p: "10px",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Info
              </Button>
            )}
          </Box>
          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {descriptionBatiment.map((value, index) => {
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        if (column.id == "cta") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteAppartement(index);
                                }}
                                sx={{ fontSize: "25px" }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: palette.primary.red,
                                    fontSize: "25px",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          );
                        } else if (column.id === "app") {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Typography
                                variant="h6"
                                component="h2"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </TableCell>
                          );
                        } else if (column.type === "select") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Select
                                placeholder={column.label}
                                name={column.key}
                                labelId={column.label}
                                id={column.label}
                                value={value[column.id]}
                                label={column.label}
                                onChange={(e) =>
                                  handleChange(index, column.id, e.target.value)
                                }
                                sx={{
                                  width: column.width,
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.options.map((option, index) => {
                                  return (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          );
                        } else if (column.type === "modal") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Button
                                variant="contained"
                                disabled={
                                  column.condition
                                    ? !column.condition(
                                        chauffageInfo.typeChauffage
                                      )
                                    : false
                                }
                                color="primary"
                                onClick={() => {
                                  openModal(column.id, index);
                                }}
                                sx={{
                                  m: "12px",
                                  p: "10px",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                Info
                              </Button>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <TextField
                              label={column.label}
                              type="text"
                              //onBlur={handleBlur}
                              onChange={(e) =>
                                handleChange(index, column.id, e.target.value)
                              }
                              value={value[column.id]}
                              name={column.id}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={addAppartement}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Ajouter
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default DescriptionBatiment;
