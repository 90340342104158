import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { Formik, Form } from "formik";
import * as yup from "yup";

import * as React from "react";

const Formulaire = ({
  formStruct,
  initialValues,
  onSubmit,
  textButton = "Enregistrer",
  children = null,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const schemaForElement = (element) => {
    if (element.type === "date") {
      return yup.date();
    } else if (element.type === "number") {
      return yup.number();
    } else if (element.type === "mail") {
      return yup.string().email("Invalid email");
    } else {
      return yup.string();
    }
  };

  const yupSchema = {};
  formStruct.forEach((element) => {
    const elementSchema = schemaForElement(element);
    if (element.conditional) {
      yupSchema[element.key] = elementSchema.when(
        element.conditional,
        (value, schema) => {
          if (value == element.conditionalValue)
            return schema.required(`${element.label} is Required`);
          return schema;
        }
      );
    } else if (element.isOptional) {
      yupSchema[element.key] = elementSchema.optional(true);
    } else {
      yupSchema[element.key] = elementSchema.required(
        `${element.label} is Required`
      );
    }
  });

  const formulaireSchema = yup.object().shape(yupSchema);

  const handleFormSubmit = async (values, onSubmitProps) => {
    onSubmit(values);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={formulaireSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": {
                gridColumn: isNonMobile ? undefined : "span 4",
              },
            }}
          >
            {formStruct.map((value, key) => {
              if (value.conditional) {
                if (value.conditionalValue != values[value.conditional]) {
                  return <></>;
                }
              }
              if (value.type === "formControl") {
                return (
                  <>
                    <FormControl
                      component="fieldset"
                      sx={{ gridColumn: "span 4" }}
                    >
                      <FormLabel component="legend">{value.label}</FormLabel>
                      <RadioGroup
                        aria-label={value.label}
                        name={value.key}
                        value={values[value.key]}
                        onChange={handleChange}
                      >
                        {value.options.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                              sx={{ gridColumn: "span 2" }}
                            />
                          );
                        })}
                      </RadioGroup>
                      {touched[value.key] && errors[value.key] && (
                        <FormHelperText error>
                          {errors[value.key]}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {value.displayOther && (
                      <TextField
                        label="Autre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[value.key]}
                        type={value.type}
                        name={value.key}
                        error={
                          Boolean(touched[value.key]) &&
                          Boolean(errors[value.key])
                        }
                        helperText={touched[value.key] && errors[value.key]}
                        sx={{ gridColumn: "span 4" }}
                      />
                    )}
                  </>
                );
              }
              if (value.type === "select") {
                return (
                  <>
                    <FormControl
                      component="fieldset"
                      sx={{ gridColumn: "span 4" }}
                    >
                      <FormLabel component="legend">{value.label}</FormLabel>
                      <Select
                        name={value.key}
                        labelId={value.label}
                        id={value.label}
                        value={values[value.key]}
                        label={value.label}
                        onChange={handleChange}
                      >
                        {value.options.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {touched[value.key] && errors[value.key] && (
                        <FormHelperText error>
                          {errors[value.key]}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {value.displayOther && (
                      <TextField
                        label="Autre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[value.key]}
                        type={value.type}
                        name={value.key}
                        error={
                          Boolean(touched[value.key]) &&
                          Boolean(errors[value.key])
                        }
                        helperText={touched[value.key] && errors[value.key]}
                        sx={{ gridColumn: "span 4" }}
                      />
                    )}
                  </>
                );
              }
              return (
                <TextField
                  key={key}
                  label={value.label}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[value.key]}
                  type={value.type}
                  name={value.key}
                  error={
                    Boolean(touched[value.key]) && Boolean(errors[value.key])
                  }
                  helperText={touched[value.key] && errors[value.key]}
                  sx={{ gridColumn: "span 4" }}
                />
              );
            })}
          </Box>

          {children}

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                m: "2rem 0",
                p: "1rem",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {textButton}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Formulaire;
