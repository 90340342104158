import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  { id: "quantite", label: "Quantité", width: 100 },
  {
    id: "type",
    label: "Type d’ouvrant",
    width: 100,
    type: "select",
    options: [
      { label: "Porte", value: "Porte" },
      { label: "Fenêtre", value: "Fenêtre" },
    ],
  },
  {
    id: "longueur",
    label: "Longueur",
    type: "number",
    minWidth: 100,
  },
  {
    id: "largeur",
    label: "Largeur",
    type: "number",
    minWidth: 100,
  },
  {
    id: "surface",
    label: "Surface",
    type: "number",
    minWidth: 100,
  },
  {
    id: "typologie",
    label: "Typologie",
    minWidth: 100,
    type: "select",
    options: [
      { label: "Pvc", value: "Pvc" },
      { label: "Bois", value: "Bois" },
      { label: "Métal", value: "Métal" },
    ],
  },
  {
    id: "vitre",
    label: "Vitre",
    minWidth: 100,
    type: "select",
    options: [
      { label: "Simple", value: "Simple" },
      { label: "Double", value: "Double" },
      { label: "Tripe", value: "Triple" },
    ],
  },
  {
    id: "volet",
    label: "Volet",
    minWidth: 100,
    type: "select",
    options: [
      { label: "Electrique", value: "Electrique" },
      { label: "Bâtant", value: "Bâtant" },
      { label: "Autre", value: "Autre" },
    ],
  },
  {
    id: "orientation",
    label: "Orientation",
    minWidth: 100,
    type: "select",
    options: [
      { label: "Nord", value: "Nord" },
      { label: "Sud", value: "Sud" },
      { label: "Est", value: "Est" },
      { label: "Ouest", value: "Ouest" },
      { label: "Nord-Ouest", value: "Nord-Ouest" },
      { label: "Nord-Est", value: "Nord-Est" },
      { label: "Sud-Ouest", value: "Sud-Ouest" },
      { label: "Sud-Est", value: "Sud-Est" },
    ],
  },
  { id: "cta", label: "Actions", minWidth: 50, align: "right" },
];

const Ouvrants = (props) => {
  const defaultValue = {};

  let initialValue = [defaultValue];

  if (props.initialValue && props.initialValue.length > 0) {
    initialValue = props.initialValue;
  }

  const { palette } = useTheme();
  const [ouvrants, setOuvrants] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (index, name, value) => {
    let newValue = Object.assign([], ouvrants);
    newValue[index][name] = value;
    if (name == "longueur" || name == "largeur") {
      const longueur = newValue[index]["longueur"];
      const largeur = newValue[index]["largeur"];
      if (longueur && largeur) {
        newValue[index]["surface"] = longueur * largeur;
      }
    }
    setOuvrants(newValue);
  };

  const addOuvrant = () => {
    let newValue = Object.assign([], ouvrants);
    newValue.push(defaultValue);
    setOuvrants(newValue);
  };

  const deleteOuvrant = (index) => {
    // let _ouvrants = Object.assign([], ouvrants);
    // delete _ouvrants[index];
    // setOuvrants(_ouvrants);

    setOuvrants((prevSystems) => prevSystems.filter((_, i) => i !== index));
  };

  const submit = async (projectId, file) => {
    props.onSubmit(ouvrants);
  };

  return (
    <Box>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ouvrants.map((value, index) => {
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        if (column.id == "cta") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteOuvrant(index);
                                }}
                                sx={{ fontSize: "25px" }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: palette.primary.red,
                                    fontSize: "25px",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          );
                        }
                        if (column.type === "select") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Select
                                placeholder={column.label}
                                name={column.key}
                                labelId={column.label}
                                id={column.label}
                                value={value[column.id]}
                                label={column.label}
                                onChange={(e) =>
                                  handleChange(index, column.id, e.target.value)
                                }
                                sx={{
                                  width: column.width,
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.options.map((option, index) => {
                                  return (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              width: column.width,
                              minWidth: column.minWidth,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <TextField
                              label={column.label}
                              type="number"
                              //onBlur={handleBlur}
                              onChange={(e) =>
                                handleChange(index, column.id, e.target.value)
                              }
                              value={value[column.id]}
                              name={column.id}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={addOuvrant}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Ajouter
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Ouvrants;
