import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import imageCompression from "browser-image-compression";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CollectionsIcon from "@mui/icons-material/Collections";
import ClearIcon from "@mui/icons-material/Clear";

import * as React from "react";
import { useState } from "react";
import FlexBetween from "components/FlexBetween";
import {
  projectService,
  toastError,
  toastWarning,
} from "services/project.service";
import { useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";

const Photos = (props) => {
  const { palette } = useTheme();

  const [photoPaths, setPhotoPaths] = useState(props.initialValue || {});
  const [previewPath, setPreviewPath] = useState(false);
  const [photoCaptureInfo, setPhotoCaptureInfo] = useState({
    source: "Gallery",
    key: null,
    index: 0,
  });

  if (isMobile || isTablet) {
  }

  const [openPhotoModal, setOpenPhotoModal] = useState(false);

  const hiddenFileInput = useRef(null);

  const elementList = {
    facadeNord: "Façades Nord",
    facadeSud: "Façades Sud",
    facadeEst: "Façades Est",
    facadeOuest: "Façades Ouest",
    murs: "Murs",
    compteursElectriques: "Compteurs électriques",
    ventilation: "Ventilation /bouches d’extraction",
    chauffage: "Système de chauffage (Chaudière) (production, distribution)",
    emetteurs: "Emetteurs (pour chaque type)",
    ballonEauChaude: "Ballon d’eau chaude",
    planBatiment: "Plan du bâtiment",
    ouvrants: "Ouvrants : portes et fenêtres (pour chaque type)",
    sousSolCave: "Sous-sol, Cave",
    plancherHaut: "Plancher haut",
    locauxNonChauffes: "Locaux non chauffés",
    autresDocuments: "Autres documents",
    autresPhotos: "Autres photos",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updateProjectPhotoComm = async () => {
      try {
        const response = await projectService.updatePhotoCommProject(
          props.projectId,
          {
            key: name,
            text: value,
          }
        );
      } catch (error) {
        console.log("update photo comm error:", error);
      }
    };
    updateProjectPhotoComm();
  };

  const handleCapture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        const options = {
          maxSizeMB: 0.8,
          maxWidthOrHeight: 1080,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          const newUrl = URL.createObjectURL(compressedFile);
          const newPath = photoPaths[photoCaptureInfo.key].photos || [];
          newPath.push(newUrl);
          submit(photoCaptureInfo.key, photoCaptureInfo.index, compressedFile);
        } catch (error) {
          console.log(error);
          toastError(error.message || "Error uploading photo");
        }

        setPhotoCaptureInfo({
          source: "Gallery",
          key: null,
          index: 0,
        });
        setOpenPhotoModal(false);
      }
    }
  };

  const deletePhotoIndexForKey = (key, index) => {
    const deletePhoto = async () => {
      try {
        const response = await projectService.deletePhotosProject(
          props.projectId,
          {
            key: key,
            index: index,
          }
        );
        setPhotoPaths(response.data?.photos);
        console.log("delete photo resp", response);
      } catch (error) {
        console.log("delete photo error:", error);
      }
    };
    deletePhoto();
  };

  const submit = async (key, index, file) => {
    const updateProjectPhoto = async () => {
      try {
        const formData = new FormData();
        formData.append("key", key);
        formData.append("index", index);
        formData.append("photo", file);
        formData.append("photoPath", file.name);

        const response = await projectService.updatePhotosProject(
          props.projectId,
          formData
        );

        setPhotoPaths(response.data?.photos);
      } catch (error) {
        console.log("upload photo error:", error);
        toastError("Erreur lors de l'envoi de la photo");
      }
    };
    updateProjectPhoto();
  };

  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        p="2rem"
        m="2rem auto"
        backgroundColor={palette.background.default}
      >
        <input
          ref={hiddenFileInput}
          name="photo"
          style={{ display: "none" }}
          accept="image/*"
          type="file"
          capture={
            photoCaptureInfo.source == "Camera" ? "environment" : undefined
          }
          onClick={(e) => {
            e.target.value = null;
          }}
          onChange={(e) => handleCapture(e.target)}
        />
        <Modal
          open={openPhotoModal}
          onClose={() => setOpenPhotoModal(false)}
          aria-labelledby="take-photo-modal"
          aria-describedby="modal to choose between gallery or camera source"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 200,
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
            }}
          >
            <FlexBetween>
              <Box>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    setPhotoCaptureInfo({
                      ...photoCaptureInfo,
                      source: "Camera",
                    });
                    setTimeout(() => {
                      hiddenFileInput.current.click();
                    }, 200);
                  }}
                >
                  <AddAPhotoIcon
                    fontSize="large"
                    sx={{ color: palette.primary.main }}
                  />
                </IconButton>
                <Typography variant="h6">Camera</Typography>
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    setPhotoCaptureInfo({
                      ...photoCaptureInfo,
                      source: "Gallery",
                    });
                    setTimeout(() => {
                      hiddenFileInput.current.click();
                    }, 200);
                  }}
                >
                  <CollectionsIcon
                    fontSize="large"
                    sx={{ color: palette.primary.main }}
                  />
                </IconButton>
                <Typography variant="h6">Gallery</Typography>
              </Box>
            </FlexBetween>
          </Box>
        </Modal>
        <Modal
          open={previewPath}
          onClose={() => setPreviewPath(false)}
          aria-labelledby="preview-photo-modal"
          aria-describedby="preview photo"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                margin: "0 4px",
                objectFit: "contain",
              }}
              src={`${process.env.REACT_APP_API_URL}/assets/${props.projectId}/${previewPath}`}
              alt={`${previewPath} snap`}
            ></img>
          </Box>
        </Modal>
        {Object.keys(elementList).map((key, index) => (
          <Box
            sx={{ gridColumn: "span 4" }}
            id={`planchers-bas-${index}`}
            key={index}
          >
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridColumn: "span 2",
                mb: "2rem",
              }}
            >
              <Typography fontWeight="bold" variant="h5" component="h2">
                {elementList[key]}
              </Typography>
              <FlexBetween
                sx={{
                  justifyContent: "space-evenly",
                }}
              >
                {photoPaths[key] &&
                  photoPaths[key].photos &&
                  photoPaths[key].photos.map((elm, index) => {
                    return (
                      <FlexBetween
                        sx={{
                          justifyContent: "space-evenly",
                          flexDirection: "column",
                          border: "1px solid #ccc",
                          borderStyle: "dashed",
                          borderRadius: "5px",
                        }}
                      >
                        <img
                          style={{
                            width: "80px",
                            height: "80px",
                            margin: "0 4px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPreviewPath(elm);
                          }}
                          src={`${process.env.REACT_APP_API_URL}/assets/${props.projectId}/${elm}`}
                          alt={`${elm} snap`}
                        ></img>
                        <IconButton
                          onClick={() => {
                            deletePhotoIndexForKey(key, index);
                          }}
                          sx={{ fontSize: "25px" }}
                        >
                          <ClearIcon
                            sx={{
                              color: palette.primary.red,
                              fontSize: "25px",
                            }}
                          />
                        </IconButton>
                      </FlexBetween>
                    );
                  })}

                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    if (!isMobile && !isTablet) {
                      setPhotoCaptureInfo({
                        source: "Gallery",
                        key: key,
                        index: index,
                      });
                      hiddenFileInput.current.click();
                    } else {
                      setPhotoCaptureInfo({
                        ...photoCaptureInfo,
                        key: key,
                        index: index,
                      });
                      setOpenPhotoModal(true);
                    }
                  }}
                >
                  <AddAPhotoIcon
                    fontSize="large"
                    sx={{ color: palette.primary.main }}
                  />
                </IconButton>
              </FlexBetween>
            </Box>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridColumn: "span 2",
                mb: "2rem",
              }}
            >
              <TextField
                name={key}
                label="Commentaire"
                defaultValue={photoPaths[key].commentaire}
                onChange={handleInputChange}
                // disabled={photoPaths[key].photos.length === 0}
                fullWidth
              />
            </Box>
            <Divider
              sx={{ gridColumn: "span 2", mt: "2rem", mb: "2rem" }}
              orientation="horizontal"
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Photos;
