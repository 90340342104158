import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  {
    id: "typeMateriaux",
    label: "Types/Matériaux",
    type: "select",
    width: 100,
    options: [
      { label: "Plafond bois", value: "Plafond bois" },
      { label: "Plafond sur solives bois", value: "Plafond sur solives bois" },
      {
        label: "Plafond sur solives métalliques",
        value: "Plafond sur solives métalliques",
      },
      {
        label: "Plafond entre solives bois",
        value: "Plafond entre solives bois",
      },
      { label: "Dalle béton", value: "Dalle béton" },
      { label: "Entrevous terre-cuite", value: "Entrevous terre-cuite" },
      {
        label: "Plafond en tôles métalliques",
        value: "Plafond en tôles métalliques",
      },
    ],
  },
  { key: "surface", label: "Surface", type: "number" },
  {
    id: "presenceIsolation",
    label: "Présence d’isolation",
    type: "select",
    options: [
      { label: "Non", value: "non" },
      { label: "Oui", value: "oui" },
    ],
  },
  {
    id: "epaisseurIsolant",
    label: "Épaisseur de l'isolant (cm)",
    type: "number",
    condition: (values) => values.presenceIsolation === "oui",
  },
  {
    id: "anneeIsolation",
    label: "Année d’isolation",
    type: "number",
    condition: (values) => values.presenceIsolation === "oui",
  },
  {
    id: "tauxIsolation",
    label: "Taux d'isolation",
    type: "select",
    options: [
      { label: "Total", value: "total" },
      { label: "Partiel", value: "partiel" },
    ],
    condition: (values) => values.presenceIsolation === "oui",
  },
  {
    id: "tauxIsolationPartiel",
    label: "Taux d’isolation partiel",
    type: "number",
    condition: (values) => values.tauxIsolation === "partiel",
  },
  {
    id: "position",
    label: "Position",
    type: "select",
    options: [
      { label: "Terrasse", value: "Terrasse" },
      { label: "Comble aménagé", value: "Comble aménagé" },
      { label: "Sur LNC", value: "Sur LNC" },
      { label: "Mitoyen", value: "Mitoyen" },
      { label: "Sur local autre", value: "Sur local autre" },
    ],
  },
  { id: "cta", label: "Actions", minWidth: 50, align: "right" },
];

const PlafondsCombles = (props) => {
  const defaultValue = {};

  let initialValue = [defaultValue];

  if (props.initialValue && props.initialValue.length > 0) {
    initialValue = props.initialValue;
  }

  const { palette } = useTheme();

  const [plafondCombles, setPlafondCombles] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (index, name, value) => {
    let newValue = Object.assign([], plafondCombles);
    newValue[index][name] = value;

    setPlafondCombles(newValue);
  };

  const addPlafond = () => {
    let newValue = Object.assign([], plafondCombles);
    newValue.push(defaultValue);
    setPlafondCombles(newValue);
  };

  const deletePlafond = (index) => {
    setPlafondCombles((prevSystems) =>
      prevSystems.filter((_, i) => i !== index)
    );
  };

  const submit = async () => {
    let cleanPlafondCombles = Object.assign([], plafondCombles);

    cleanPlafondCombles.map((cleanParoi) => {
      if (cleanParoi.presenceIsolation === "non") {
        delete cleanParoi.epaisseurIsolant;
        delete cleanParoi.anneeIsolation;
        delete cleanParoi.tauxIsolation;
        delete cleanParoi.tauxIsolationPartiel;
      } else if (cleanParoi.tauxIsolation === "total") {
        delete cleanParoi.tauxIsolationPartiel;
      }
    });

    props.onSubmit(cleanPlafondCombles);
  };

  return (
    <Box>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {plafondCombles.map((value, index) => {
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        if (column.id == "cta") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletePlafond(index);
                                }}
                                sx={{ fontSize: "25px" }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: palette.primary.red,
                                    fontSize: "25px",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          );
                        }
                        if (column.type === "select") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Select
                                disabled={
                                  column.condition
                                    ? !column.condition(value)
                                    : false
                                }
                                placeholder={column.label}
                                name={column.key}
                                labelId={column.label}
                                id={column.label}
                                value={value[column.id]}
                                label={column.label}
                                onChange={(e) =>
                                  handleChange(index, column.id, e.target.value)
                                }
                                sx={{
                                  width: column.width,
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.options.map((option, index) => {
                                  return (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              width: column.width,
                              minWidth: column.minWidth,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <TextField
                              label={column.label}
                              type="text"
                              disabled={
                                column.condition
                                  ? !column.condition(value)
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(index, column.id, e.target.value)
                              }
                              value={value[column.id]}
                              name={column.id}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={addPlafond}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Ajouter
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PlafondsCombles;
