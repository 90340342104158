import { projectService, toastSuccess } from "services/project.service";

export const offlineManager = {
  saveData,
  getData,
  syncData,
};

const key = "tempProjectData";

function saveData(projectId, tab, data) {
  let currentData = localStorage.getItem(key);
  if (!currentData) {
    currentData = "{}";
  }
  currentData = JSON.parse(currentData);
  if (!currentData[projectId]) {
    currentData[projectId] = {};
  }
  currentData[projectId][tab] = data;
  localStorage.setItem(key, JSON.stringify(currentData));
}

function getData(projectId = null, tab = null) {
  let data = localStorage.getItem(key);

  if (!data) {
    return data;
  }
  data = JSON.parse(data);

  if (projectId) {
    const projectData = data[projectId];
    if (projectData && tab) {
      data = projectData[tab];
    } else {
      data = projectData;
    }
  }
  if (!data) {
    return data;
  }
  console.log("data: ", data);
  return data;
}

async function syncData() {
  const tempData = getData();
  if (!tempData) {
    return;
  }
  const response = await projectService.syncData({ data: tempData });
  if (response && response.status == "ok") {
    localStorage.removeItem(key);
    toastSuccess("Successfully synced data");
  }
}
