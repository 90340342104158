import { Box, useTheme } from "@mui/material";

import * as React from "react";
import Formulaire from "components/Formulaire";
import croquis from "./../../assets/croquis_comble.png";

const Combles = (props) => {
  const { palette } = useTheme();

  const formStruct = [
    { key: "surface", label: "Surface", type: "number" },
    {
      key: "hauteurSousPlafond",
      label: "Hauteur sous-plafond",
      type: "number",
    },
    {
      key: "comportementThermique",
      label: "Comportement thermique",
      type: "formControl",
      options: [
        { label: "Local Chauffé", value: "Local Chauffé" },
        { label: "LNC", value: "LNC" },
      ],
    },
    {
      key: "accessibilite",
      label: "Accessibilité",
      type: "formControl",
      options: [
        { label: "Trappe", value: "Trappe" },
        { label: "Escalier", value: "Escalier" },
      ],
      displayOther: true,
    },
    { key: "longueur1", label: "Longueur 1", type: "number" },
    { key: "longueur2", label: "Longueur 2", type: "number" },
    { key: "longueur3", label: "Longueur 3", type: "number" },
    { key: "longueur4", label: "Longueur 4", type: "number" },
    { key: "hauteur1", label: "Hauteur 1", type: "number" },
    { key: "hauteur2", label: "Hauteur 2", type: "number" },
  ];

  const initialValues = {
    surface: "",
    hauteurSousPlafond: "",
    comportementThermique: "",
    accessibilite: "",
    longueur1: "",
    longueur2: "",
    longueur3: "",
    longueur4: "",
    hauteur1: "",
    hauteur2: "",
  };

  return (
    <Box
      width={"100%"}
      p="2rem"
      mt="2rem"
      backgroundColor={palette.background.default}
    >
      <img style={{ height: "250px" }} src={croquis} alt="green-energy" />
      <Formulaire
        formStruct={formStruct}
        initialValues={props.initialValue || initialValues}
        onSubmit={props.onSubmit}
      />
    </Box>
  );
};

export default Combles;
