import { Box, useMediaQuery, useTheme } from "@mui/material";

import * as React from "react";
import Formulaire from "components/Formulaire";

const SousSol = (props) => {
  const { palette } = useTheme();

  const formStruct = [
    { key: "surface", label: "Surface", type: "number" },
    {
      key: "hauteurSousPlafond",
      label: "Hauteur sous-plafond",
      type: "number",
    },
    {
      key: "comportementThermique",
      label: "Comportement thermique",
      type: "formControl",
      options: [
        { label: "Local Chauffé", value: "Local Chauffé" },
        { label: "LNC", value: "LNC" },
      ],
    },
    {
      key: "accessibilite",
      label: "Accessibilité",
      type: "formControl",
      options: [
        { label: "Trappe", value: "Trappe" },
        { label: "Escalier", value: "Escalier" },
      ],
      displayOther: true,
    },
  ];

  const initialValues = {
    surface: "",
    hauteurSousPlafond: "",
    comportementThermique: "",
    accessibilite: "",
    accessibiliteAutre: "",
  };

  return (
    <Box
      width={"100%"}
      p="2rem"
      m="2rem auto"
      backgroundColor={palette.background.default}
    >
      <Formulaire
        formStruct={formStruct}
        initialValues={props.initialValue || initialValues}
        onSubmit={props.onSubmit}
      />
    </Box>
  );
};

export default SousSol;
