import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";

const columns = [
  {
    id: "energyType",
    label: "Types d'énergie",
    width: 100,
    type: "select",
    options: [
      { label: "Electrique", value: "Electrique" },
      { label: "Gaz", value: "Gaz" },
      { label: "Fioul", value: "Fioul" },
      { label: "Bois", value: "Bois" },
      { label: "Charbon", value: "Charbon" },
      { label: "Réseau de chaleur", value: "Réseau de chaleur" },
      { label: "Pompe à chaleur", value: "Pompe à chaleur" },
    ],
  },
  {
    id: "emitter",
    label: "Emetteur",
    width: 150,
    type: "select",
    options: [
      { label: "Radiateur avec vannes", value: "Radiateur avec vannes" },
      { label: "Radiateurs sans vannes", value: "Radiateurs sans vannes" },
      { label: "Plancher chauffant", value: "Plancher chauffant" },
      { label: "Poêle", value: "Poêle" },
      { label: "Air soufflé", value: "Air soufflé" },
    ],
  },
  {
    id: "emitterType",
    label: "Matiere d’emetteur",
    width: 80,
    type: "select",
    options: [
      { label: "Fonte", value: "Fonte" },
      { label: "Allu", value: "Allu" },
      { label: "Acier", value: "Acier" },
    ],
  },
  {
    id: "power",
    label: "Puissance (en KW)",
    minWidth: 80,
  },
  {
    id: "location",
    label: "Emplacement",
    minWidth: 200,
    type: "select",
    options: [
      { label: "Volume chauffé", value: "Volume chauffé" },
      { label: "Volume non chauffé", value: "Volume non chauffé" },
    ],
  },
];

const Chauffage = (props) => {
  const defaultValue = {};

  let initialValue = defaultValue;

  if (props.initialValue) {
    initialValue = props.initialValue;
  }

  const { palette } = useTheme();
  const [chauffage, setChauffage] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (name, value) => {
    let newValue = Object.assign({}, chauffage);
    newValue[name] = value;
    if (name == "longueur" || name == "largeur") {
      const longueur = newValue["longueur"];
      const largeur = newValue["largeur"];
      if (longueur && largeur) {
        newValue["surface"] = longueur * largeur;
      }
    }
    setChauffage(newValue);
  };

  const submit = async () => {
    props.onSubmit(chauffage);
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            gridColumn: "span 4",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={submit}
            sx={{
              m: "12px",
              p: "10px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Enregistrer
          </Button>
        </Box>

        <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow role="checkbox" tabIndex={-1}>
                {columns.map((column) => {
                  if (column.type === "select") {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          width: column.width,
                          minWidth: column.minWidth,
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        <Select
                          placeholder={column.label}
                          name={column.key}
                          labelId={column.label}
                          id={column.label}
                          value={chauffage[column.id]}
                          label={column.label}
                          onChange={(e) =>
                            handleChange(column.id, e.target.value)
                          }
                          sx={{
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.options.map((option, index) => {
                            return (
                              <MenuItem value={option.value}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        width: column.width,
                        minWidth: column.minWidth,
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      <TextField
                        label={column.label}
                        type="text"
                        //onBlur={handleBlur}
                        onChange={(e) =>
                          handleChange(column.id, e.target.value)
                        }
                        value={chauffage[column.id]}
                        name={column.id}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Chauffage;
