import CeraProLight from "./fonts/CeraProLight.woff";
import CeraProMedium from "./fonts/CeraProMedium.woff";
// color design tokens export
export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#EEF4F4",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
  primary: {
    50: "#95C6C4",
    100: "#85B5B1",
    200: "#75A59F",
    300: "#66948C",
    400: "#56847A",
    500: "#467367",
    600: "#217166",
    700: "#A1E0DA",
    800: "#24551f",
    900: "#153313",
    red: "#941b0c",
  },
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              dark: colorTokens.primary[200],
              main: colorTokens.primary[500],
              light: colorTokens.primary[800],
            },
            neutral: {
              dark: colorTokens.grey[100],
              main: colorTokens.grey[200],
              mediumMain: colorTokens.grey[300],
              medium: colorTokens.grey[400],
              light: colorTokens.grey[700],
            },
            background: {
              default: colorTokens.grey[900],
              alt: colorTokens.grey[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              dark: colorTokens.primary[700],
              main: colorTokens.primary[500],
              light: colorTokens.primary[50],
              red: colorTokens.primary["red"],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[0],
              alt: colorTokens.grey[10],
            },
          }),
    },
    typography: {
      fontFamily: ["Cera Pro", "Sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Cera Pro Medium", "Sans-serif"].join(","),
        fontSize: 14,
      },
      fontWeightLight: 400, // Poids pour la variante "light"
      fontWeightMedium: 700, // Poids pour la variante "medium"
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Cera Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Cera Pro'), local('CeraPro-Light'), url(${CeraProLight}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          },
          @font-face {
            font-family: 'Cera Pro Medium';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('Cera Pro Medium'), local('CeraPro-Medium'), url(${CeraProMedium}) format('woff');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
    },
  };
};
