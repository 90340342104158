import * as React from "react";
import Typography from "@mui/material/Typography";
import Navbar from "scenes/navbar";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { projectService } from "services/project.service";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Report = () => {
  const { palette } = useTheme();
  let { id } = useParams();
  const [project, setProject] = useState(null);
  const [displayBackdrop, setDisplayBackdrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const getProject = async () => {
    try {
      const response = await projectService.getProject(id);
      setProject(response);
    } catch (error) {
      console.log("an error occurred:", error);
    }
  };

  useEffect(() => {
    getProject();
  }, [id]);

  const uploadCsv = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        submit(file);
      }
    }
  };

  const submit = async (file) => {
    const updateProjectCsv = async () => {
      try {
        const formData = new FormData();
        formData.append("bao_csv", file);
        formData.append("filePath", file.name);
        await projectService.updateCsvProject(id, formData);
        getProject(id);
      } catch (error) {
        console.log("upload csv error:", error);
      }
    };
    updateProjectCsv();
  };

  const generateReport = () => {
    const generateReport = async () => {
      try {
        await projectService.downloadReport(id);
        getProject(id);
        setDisplayBackdrop(false);
      } catch (error) {
        setDisplayBackdrop(false);
        console.log("an error occurred !!!", error);
      }
    };
    setDisplayBackdrop(true);
    generateReport();
  };

  const downloadReport = () => {
    fetch(`${process.env.REACT_APP_API_URL}/assets/${id}/${project.reportPath}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = project.reportPath;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const downloadCsv = () => {
    fetch(`${process.env.REACT_APP_API_URL}/assets/${id}/${project.csvPath}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = project.csvPath;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  // CSV 2

  const uploadCsv2 = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        submit2(file);
      }
    }
  };

  const submit2 = async (file) => {
    const updateProjectCsv = async () => {
      try {
        const formData = new FormData();
        formData.append("bao_csv_2", file);
        formData.append("filePath", file.name);
        await projectService.updateCsvProject2(id, formData);
        getProject(id);
      } catch (error) {
        console.log("upload csv error:", error);
      }
    };
    updateProjectCsv();
  };

  const generateReport2 = () => {
    const generateReport = async () => {
      try {
        await projectService.downloadReport2(id);
        getProject(id);
        setDisplayBackdrop(false);
      } catch (error) {
        setDisplayBackdrop(false);
        console.log("an error occurred !!!", error);
      }
    };
    setDisplayBackdrop(true);
    generateReport();
  };

  const downloadReport2 = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/assets/${id}/${project.reportPath2}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = project.reportPath2;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const downloadCsv2 = () => {
    fetch(`${process.env.REACT_APP_API_URL}/assets/${id}/${project.csvPath2}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = project.csvPath2;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  if (!project) {
    return (
      <>
        <Navbar />
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 120px)",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="clamp(1rem, 2rem, 2.25rem)"
            color="primary"
            textAlign="center"
            sx={{ mt: "20px" }}
          >
            Loading...
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={displayBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="clamp(1rem, 2rem, 2.25rem)"
        color="primary"
        textAlign="center"
        sx={{ mt: "20px" }}
      >
        {project?.name}
      </Typography>
      <Divider sx={{ m: "40px" }} />

      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="20px"
        sx={{ ml: "20px" }}
      >
        CSV
      </Typography>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ fontSize: "10px", m: "20px" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        CSV
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            document.getElementById(`icon-button-file-${id}`).click()
          }
        >
          Upload
        </MenuItem>
        <MenuItem
          disabled={!project.csvPath}
          onClick={() => {
            setAnchorEl(null);
            downloadCsv();
          }}
        >
          Download
        </MenuItem>
      </Menu>

      <input
        style={{ display: "none" }}
        name="bao_csv"
        accept={".csv"}
        id={`icon-button-file-${id}`}
        type="file"
        onClick={(e) => {
          e.stopPropagation();
          e.target.value = null;
        }}
        onChange={(e) => {
          uploadCsv(e.target);
        }}
      />
      {project?.csvPath && (
        <Typography
          fontWeight="bold"
          fontSize="16px"
          color="primary"
          sx={{ ml: "20px" }}
        >
          {project.csvPath}
        </Typography>
      )}

      <Divider sx={{ m: "40px" }} />
      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="20px"
        sx={{ ml: "20px" }}
      >
        DOCX
      </Typography>

      <Button
        disabled={!project.csvPath}
        type="submit"
        sx={{ fontSize: "10px", m: "20px" }}
        onClick={(e) => {
          e.stopPropagation();
          generateReport();
        }}
        variant="contained"
        color="primary"
      >
        {project.reportPath ? "Régénérer le Rapport" : "Générer le Rapport"}
      </Button>

      {project?.reportPath && (
        <>
          <Button
            disabled={!project.reportPath}
            type="submit"
            sx={{ fontSize: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              downloadReport();
            }}
            variant="contained"
            color="primary"
          >
            Télécharger
          </Button>

          <Typography
            fontWeight="bold"
            fontSize="16px"
            color="primary"
            sx={{ ml: "20px" }}
          >
            {project.reportPath}
          </Typography>
        </>
      )}

      <Divider sx={{ m: "40px" }} />

      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="20px"
        sx={{ ml: "20px" }}
      >
        CSV 2
      </Typography>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ fontSize: "10px", m: "20px" }}
        onClick={(e) => {
          setAnchorEl2(e.currentTarget);
        }}
      >
        CSV 2
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={() => setAnchorEl2(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() =>
            document.getElementById(`icon-button-file-2-${id}`).click()
          }
        >
          Upload
        </MenuItem>
        <MenuItem
          disabled={!project.csvPath2}
          onClick={() => {
            setAnchorEl2(null);
            downloadCsv2();
          }}
        >
          Download
        </MenuItem>
      </Menu>

      <input
        style={{ display: "none" }}
        name="bao_csv_2"
        accept={".csv"}
        id={`icon-button-file-2-${id}`}
        type="file"
        onClick={(e) => {
          e.stopPropagation();
          e.target.value = null;
        }}
        onChange={(e) => {
          uploadCsv2(e.target);
        }}
      />
      {project?.csvPath2 && (
        <Typography
          fontWeight="bold"
          fontSize="16px"
          color="primary"
          sx={{ ml: "20px" }}
        >
          {project.csvPath2}
        </Typography>
      )}

      <Divider sx={{ m: "40px" }} />
      <Typography
        variant="h5"
        fontWeight="bold"
        fontSize="20px"
        sx={{ ml: "20px" }}
      >
        DOCX 2
      </Typography>

      <Button
        disabled={!project.csvPath}
        type="submit"
        sx={{ fontSize: "10px", m: "20px" }}
        onClick={(e) => {
          e.stopPropagation();
          generateReport2();
        }}
        variant="contained"
        color="primary"
      >
        {project.reportPath2 ? "Régénérer le Rapport" : "Générer le Rapport"}
      </Button>

      {project?.reportPath2 && (
        <>
          <Button
            disabled={!project.reportPath2}
            type="submit"
            sx={{ fontSize: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              downloadReport2();
            }}
            variant="contained"
            color="primary"
          >
            Télécharger
          </Button>

          <Typography
            fontWeight="bold"
            fontSize="16px"
            color="primary"
            sx={{ ml: "20px" }}
          >
            {project.reportPath2}
          </Typography>
        </>
      )}

      {project?.reportPath && (
        <DocViewer
          documents={[
            {
              uri: `${process.env.REACT_APP_API_URL}/assets/${id}/${project.reportPath}`,
              fileType: "docx",
              fileName: "Preview Rapport",
            },
          ]}
          pluginRenderers={DocViewerRenderers}
        />
      )}

      {project?.reportPath2 && (
        <DocViewer
          documents={[
            {
              uri: `${process.env.REACT_APP_API_URL}/assets/${id}/${project.reportPath2}`,
              fileType: "docx",
              fileName: "Preview Rapport 2",
            },
          ]}
          pluginRenderers={DocViewerRenderers}
        />
      )}
    </>
  );
};

export default Report;
