import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";

const columns = [
  {
    id: "energyType",
    label: "Types d'énergie",
    width: 100,
    type: "select",
    options: [
      { label: "Electrique", value: "Electrique" },
      { label: "Gaz", value: "Gaz" },
      { label: "Fioul", value: "Fioul" },
      { label: "Bois", value: "Bois" },
      { label: "Charbon", value: "Charbon" },
      { label: "Réseau de chaleur", value: "Réseau de chaleur" },
      { label: "Pompe à chaleur", value: "Pompe à chaleur" },
    ],
  },
  {
    id: "emitter",
    label: "Emetteur",
    width: 200,
    type: "select",
    options: [
      { label: "Radiateur avec vannes", value: "Radiateur avec vannes" },
      { label: "Radiateurs sans vannes", value: "Radiateurs sans vannes" },
      { label: "Plancher chauffant", value: "Plancher chauffant" },
      { label: "Poêle", value: "Poêle" },
      { label: "Air soufflé", value: "Air soufflé" },
    ],
  },
  {
    id: "emitterType",
    label: "Matiere d’emetteur",
    width: 120,
    type: "select",
    options: [
      { label: "Fonte", value: "Fonte" },
      { label: "Allu", value: "Allu" },
      { label: "Acier", value: "Acier" },
    ],
  },
  {
    id: "power",
    label: "Puissance (en KW)",
    minWidth: 100,
  },
  {
    id: "location",
    label: "Emplacement",
    minWidth: 200,
    type: "select",
    options: [
      { label: "Volume chauffé", value: "Volume chauffé" },
      { label: "Volume non chauffé", value: "Volume non chauffé" },
    ],
  },
];

const Chauffages = (props) => {
  let initialValue = [{}];

  let initialChauffageInfo = {
    isOpen: false,
    typeChauffage: null,
    currentChauffage: null,
    data: {},
  };

  if (
    props.initialValue &&
    props.initialValue.appartements &&
    props.initialValue.appartements.length > 0
  ) {
    initialValue = props.initialValue.appartements;
  }

  if (props.initialValue && props.initialValue.chauffageInfo) {
    initialChauffageInfo = props.initialValue.chauffageInfo;
  }

  const [descriptionBatiment, setDescriptionBatiment] = useState(initialValue);
  const [chauffageInfo, setChauffageInfo] = useState(initialChauffageInfo);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (index, name, value) => {
    if (index == "Collectif") {
      const ch = Object.assign({}, chauffageInfo);
      ch.data[name] = value;
      setChauffageInfo(ch);
    } else {
      const d = Object.assign([], descriptionBatiment);
      if (!d[index].chauffage || d[index].chauffage == "") {
        d[index].chauffage = {};
      }
      d[index].chauffage[name] = value;
      setDescriptionBatiment(d);
    }
  };

  const submit = async () => {
    const data = {};

    data.chauffageInfo = chauffageInfo;
    data.appartements = descriptionBatiment;
    props.onSubmit(data);
  };

  const getTableBodyContent = () => {
    if (chauffageInfo.typeChauffage == "Collectif") {
      return (
        <TableRow role="checkbox" tabIndex={-1}>
          {columns.map((column) => {
            if (column.type === "select") {
              return (
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    width: column.width,
                    minWidth: column.minWidth,
                  }}
                  key={column.id}
                  align={column.align}
                >
                  <Select
                    placeholder={column.label}
                    name={column.key}
                    labelId={column.label}
                    id={column.label}
                    value={chauffageInfo.data?.[column.id]}
                    label={column.label}
                    onChange={(e) =>
                      handleChange("Collectif", column.id, e.target.value)
                    }
                    sx={{
                      width: column.width,
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.options.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              );
            }
            return (
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  width: column.width,
                  minWidth: column.minWidth,
                }}
                key={column.id}
                align={column.align}
              >
                <TextField
                  label={column.label}
                  type="text"
                  //onBlur={handleBlur}
                  onChange={(e) =>
                    handleChange("Collectif", column.id, e.target.value)
                  }
                  value={chauffageInfo.data?.[column.id]}
                  name={column.id}
                />
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
    return descriptionBatiment.map((appartement, index) => {
      return (
        <TableRow key={index} role="checkbox" tabIndex={-1}>
          {columns.map((column) => {
            if (column.type === "select") {
              return (
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    width: column.width,
                    minWidth: column.minWidth,
                  }}
                  key={column.id}
                  align={column.align}
                >
                  <Select
                    placeholder={column.label}
                    name={column.key}
                    labelId={column.label}
                    id={column.label}
                    value={appartement.chauffage?.[column.id]}
                    label={column.label}
                    onChange={(e) =>
                      handleChange(index, column.id, e.target.value)
                    }
                    sx={{
                      width: column.width,
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.options.map((option, index) => {
                      return (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              );
            }
            return (
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  width: column.width,
                  minWidth: column.minWidth,
                }}
                key={column.id}
                align={column.align}
              >
                <TextField
                  label={column.label}
                  type="text"
                  //onBlur={handleBlur}
                  onChange={(e) =>
                    handleChange(index, column.id, e.target.value)
                  }
                  value={appartement.chauffage?.[column.id]}
                  name={column.id}
                />
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  if (!chauffageInfo.typeChauffage) {
    return (
      <Box
        sx={{
          m: "40px",
        }}
      >
        <Typography variant="h2" textAlign={"center"}>
          {" "}
          Renseignez les informations du chauffage depuis l’onglet "Description
          du bâtiment"
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <Typography variant="h3">
            Méthode de distribution : {chauffageInfo.typeChauffage}
          </Typography>

          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{getTableBodyContent()}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default Chauffages;
