import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  {
    id: "niveau",
    label: "Niveau",
    type: "select",
    options: [],
  },
  {
    id: "surface",
    label: "Surface (en m2)",
    type: "number",
  },
  {
    id: "hauteurSousPlafond",
    label: "Hauteur sous-plafond",
    type: "number",
  },
  {
    id: "comportementThermique",
    label: "Comportement thermique",
    type: "select",
    options: [
      { label: "Local Chauffé", value: "Local Chauffé" },
      { label: "LNC", value: "LNC" },
    ],
  },
  { id: "cta", label: "Actions", minWidth: 50, align: "right" },
];

const Niveaux = (props) => {
  const defaultValue = {};

  let initialValue = [defaultValue];

  const niveauOptions = [];

  for (let i = -6; i <= 30; i++) {
    if (i == 0) {
      niveauOptions.push({ label: "RDC", value: "RDC" });
    } else {
      niveauOptions.push({ label: i, value: i });
    }
  }

  columns[0].options = niveauOptions;

  if (props.initialValue && props.initialValue.length > 0) {
    initialValue = props.initialValue;
  }

  const { palette } = useTheme();

  const [niveaux, setNiveaux] = useState(initialValue);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleChange = (index, name, value) => {
    let newValue = Object.assign([], niveaux);
    newValue[index][name] = value;

    setNiveaux(newValue);
  };

  const addNiveau = () => {
    let newValue = Object.assign([], niveaux);
    newValue.push(defaultValue);
    setNiveaux(newValue);
  };

  const deleteNiveau = (index) => {
    setNiveaux((prevSystems) => prevSystems.filter((_, i) => i !== index));
  };

  const submit = async () => {
    props.onSubmit(niveaux);
  };

  return (
    <Box>
      <Box>
        <Paper
          sx={{
            ml: "24px",
            mr: "20px",
            mt: "10px",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Enregistrer
            </Button>
          </Box>
          <TableContainer sx={{ maxHeight: screenSize.height - 320 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        minWidth: column.minWidth,
                        width: column.width,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {niveaux.map((value, index) => {
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        if (column.id == "cta") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteNiveau(index);
                                }}
                                sx={{ fontSize: "25px" }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: palette.primary.red,
                                    fontSize: "25px",
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          );
                        }
                        if (column.type === "select") {
                          return (
                            <TableCell
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              <Select
                                disabled={
                                  column.condition
                                    ? !column.condition(value)
                                    : false
                                }
                                placeholder={column.label}
                                name={column.key}
                                labelId={column.label}
                                id={column.label}
                                value={value[column.id]}
                                label={column.label}
                                onChange={(e) =>
                                  handleChange(index, column.id, e.target.value)
                                }
                                sx={{
                                  width: column.width,
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.options.map((option, index) => {
                                  return (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              width: column.width,
                              minWidth: column.minWidth,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            <TextField
                              label={column.label}
                              type="text"
                              disabled={
                                column.condition
                                  ? !column.condition(value)
                                  : false
                              }
                              onChange={(e) =>
                                handleChange(index, column.id, e.target.value)
                              }
                              value={value[column.id]}
                              name={column.id}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={addNiveau}
              sx={{
                m: "12px",
                p: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Ajouter
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Niveaux;
